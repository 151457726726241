import { http, HttpResponse } from 'msw';
import {
  createApplicationRoles,
  createHelpdesk,
  createOrganizations,
  createServiceAccounts,
  createSuperAdmin,
  createUsers,
} from './mocks';

function find<T extends { id: string }>(list: T[], id: string | readonly string[]) {
  return list.find(item => item.id === id.toString());
}

export const handlers = [
  /* Users */

  http.get('*/idm/v2/users', () => HttpResponse.json(createUsers())),
  http.get('*/idm/v2/users/:id', ({ params }) => {
    const allUsers = createUsers();
    allUsers.push(createSuperAdmin()); // we need to add the super admin to the list of users to be able to find it
    allUsers.push(createHelpdesk());
    const user = find(allUsers, params.id);
    return user ? HttpResponse.json(user) : new HttpResponse(null, { status: 404 });
  }),
  http.post(
    '*/idm/v2/users',
    () =>
      new HttpResponse(null, {
        status: 204,
        headers: { Location: 'https://dev.idp.cmf.energysector.nl/idm/v2/users/48f04657-ad47-41e1-966e-65fe9f96a888' },
      })
  ),
  http.put('*/idm/v2/users/:id', () => new HttpResponse(null, { status: 204 })),
  http.delete('*/idm/v2/users/:id', () => new HttpResponse(null, { status: 200 })),
  http.post('*/idm/v2/users/:id/reinvite', () => new HttpResponse(null, { status: 204 })),
  http.post('*/idm/v2/users/:id/resetpassword', ({ params }) => {
    return params.id ? new HttpResponse(null, { status: 204 }) : new HttpResponse(null, { status: 404 });
  }),

  /* Market participants */

  http.get('*/idm/v2/organizations', () => HttpResponse.json(createOrganizations())),
  http.get('*/idm/v2/organizations/:id', ({ params }) => {
    const marketParticipant = find(createOrganizations(), params.id);
    return marketParticipant ? HttpResponse.json(marketParticipant) : new HttpResponse(null, { status: 404 });
  }),
  http.put('*/idm/v2/organizations/:id', () => new HttpResponse(null, { status: 204 })),

  /* Service accounts */

  http.get('*/idm/v2/service-accounts', () => HttpResponse.json(createServiceAccounts())),
  http.get('*/idm/v2/service-accounts/:id', ({ params }) => {
    const serviceAccount = find(createServiceAccounts(), params.id);
    return serviceAccount ? HttpResponse.json(serviceAccount) : new HttpResponse(null, { status: 404 });
  }),
  http.post(
    '*/idm/v2/service-accounts',
    () =>
      new HttpResponse(null, {
        status: 204,
        headers: {
          Location: 'https://dev.idp.cmf.energysector.nl/idm/v2/service-accounts/f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
        },
      })
  ),
  http.put('*/idm/v2/service-accounts/:id', () => new HttpResponse(null, { status: 204 })),
  http.delete('*/idm/v2/service-accounts/:id', () => new HttpResponse(null, { status: 200 })),

  /* Application roles */

  http.get('*/idm/v1/application-roles', () => {
    return HttpResponse.json(createApplicationRoles());
  }),
  http.get('*/idm/v1/application-roles/:id', ({ params }) => {
    const role = find(createApplicationRoles(), params.id);
    return role ? HttpResponse.json(role) : new HttpResponse(null, { status: 404 });
  }),
  http.patch('*/idm/v1/application-roles/:id/service-accounts', () => new HttpResponse(null, { status: 200 })),
  http.patch('*/idm/v1/application-roles/:id/users', () => new HttpResponse(null, { status: 200 })),
];
